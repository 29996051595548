<!--
 * @Author: faf
 * @desc: 停车收入分析
 * @Date: 2022-07-08 17:20:49
 * @LastEditors: faf
 * @ LastEditTime:
-->
<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect"></a-park-type-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.park_name')">
          <a-park-select
            ref="parkSelect"
            :instance="this"
            parkTypeRefName="parkTypeSelect"
            operationRefName="operationSelect"
            areaRefName="cascader"
          ></a-park-select>
        </el-form-item> -->
              <!-- <el-form-item :label="$t('searchModule.charge_type')">
          <el-select v-model.trim="formInline.scopeId" filterable size="15">
            <el-option label="全部" value=""></el-option>
            <el-option label="一类区" value="1"></el-option>
            <el-option label="二类区" value="2"></el-option>
            <el-option label="三类区" value="3"></el-option>
          </el-select>
        </el-form-item> -->
              <el-form-item label="时间">
                <a-date-picker
                  ref="datePicker"
                  :YearShowHidden="selectkeys[3]"
                  :selectkeys="selectkeys"
                  :isPreviousday="isPreviousday"
                ></a-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button type="primary" icon="el-icon-search" @click="searchData" :loading="loading"
                >查询
              </el-button>
              <el-button type="info" icon="el-icon-delete" @click="resetForm()" :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <el-button
                :loading="loading"
                type="info"
                icon="el-icon-upload2"
                @click="exportFileList"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>

      <!-- 图表 -->
      <div class="chart-wrapper">
        <div class="chart-top-wrap">
          <div class="chart1-wrap">
            <div class="top paddingLR20 header-wrapper padding5">
              <graphTitle :title="'收入排行榜'"></graphTitle>
              <!-- <span>导出</span> -->
            </div>
            <parkTop
              @getranking="getranking"
              :parkTopList="parkTopList"
              :tabPanList="tabPanList"
            ></parkTop>
          </div>
          <div class="chart2-wrap">
            <div class="top paddingLR20 header-wrapper padding16">
              <graphTitle :title="'区域收入占比'"></graphTitle>
              <!-- <span>导出</span> -->
            </div>
            <regional-income-proportion :chartParam="chart1Param"></regional-income-proportion>
          </div>
        </div>
        <div class="chart-bottom-wrap">
          <div class="top paddingLR20 header-wrapper padding5">
            <graphTitle :title="'区域收入对比分析'"></graphTitle>
            <!-- <span>导出</span> -->
          </div>
          <comparative-analysis-income :chartParam="chart2Param"></comparative-analysis-income>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew, formatWeekDate } from "@/common/js/public.js";
import parkTop from "@/components/parkingFeeAnalysis/parkTop.vue";
import graphTitle from "@/components/graphTitle";
import regionalIncomeProportion from "@/components/parkingFeeAnalysis/regionalIncomeProportion";
import comparativeAnalysisIncome from "@/components/parkingFeeAnalysis/comparativeAnalysisIncome";
export default {
  name: "incomeAnalysis",
  data() {
    const startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    const endTime = new Date();
    endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    return {
      isPreviousday: true,
      selectkeys: ["date", "month", "week", "year", "datetime"],
      rankType: 0,
      orderType: 0,
      defaultTime: [startTime, endTime],
      parkTopList: [],
      tabPanList: [
        {
          label: "车场",
          name: "1",
        },
        {
          label: this.$t("list.region"),
          name: "2",
        },
      ],
      chart1Param: {
        pieData: [],
        textData: "",
      },
      chart2Param: {
        // xData: ["2012", "2013", "2014", "2015", "2016", "2012", "2013", "2014", "2015", "2016",
        // "2012", "2013", "2014", "2015", "2016", "2012", "2013", "2014", "2015", "2016"],
        // xData: ["2012", "2013", "2014", "2015", "2016"],
        // legendData: ["区域1", "区域2", "区域3", "区域4", "区域5"],
        // barData: [320, 332, 301, 334, 390]
      },
      // 区域列表
      areaList: [],
      // 区域街道
      areaList2: [],
      // 计费类型
      billingTypes: [],
      loading: false,
      formInline: {
        // 区域
        areaId: "",
        // 区域
        streetId: "",
        // 车场ID
        parkId: "",
        // 车场名称
        parkName: "",
        // 计费类型
        scopeId: "",
        startTime: "",
        endTime: "",
        dateType: "day",
      },
    };
  },
  components: {
    parkTop,
    graphTitle,
    regionalIncomeProportion,
    comparativeAnalysisIncome,
  },
  watch: {},
  methods: {
    getranking(val, num) {
      this.rankType = val;
      this.orderType = num;
      this.getRankingList(val, num);
    },

    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 清空车场组件信息
    resetCarPark() {
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      // this.$refs.parkInput.setShowVal("");
    },
    // 清空
    resetForm() {
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.formInline.scopeId = "";
      this.formInline.dateType = "day";
      this.resetCarPark();
      // const startTime = new Date();
      // startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
      // const endTime = new Date();
      // endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
      // this.startTime = startTime
      // this.endTime = endTime
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      // this.parkClear();
      this.searchData();
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },

    /**
     * 获取区域收入对比分析
     */
    getIncomeAnalysis() {
      this.$axios
        .get("/acb/2.0/parkIncomeAnalysis/analysis", {
          data: {
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let data = {
              legendData: [],
              xData: [],
              barData: [],
            };
            data.barData = res.value;
            res.value &&
              res.value.forEach((element, ind) => {
                for (let i in element) {
                  data.xData.push(i);
                  element[i].forEach((ele) => {
                    data.legendData.push(ele.areaName);
                    data.legendData = this.uniq(data.legendData);
                  });
                }
              });
            this.chart2Param = data;
          }
        });
    },

    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },

    /**
     * 获取区域收入占比
     */
    getRevenueRatio() {
      this.$axios
        .get("/acb/2.0/parkIncomeAnalysis/ratio", {
          data: {
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let data = [];
            let textData = 0;
            res.value &&
              res.value.forEach((val) => {
                if (val.areaName !== "全部") {
                  if (val.actualPay > 0) {
                    data.push({
                      value: val.actualPay,
                      name: val.areaName,
                    });
                    textData += Number(val.actualPay);
                  }
                } else {
                  textData = val.actualPay;
                }
              });
            this.chart1Param.textData = textData;
            this.chart1Param.pieData = data;
          }
        });
    },

    /**
     * 获取车场排行榜
     */
    getRankingList(rankType, orderType) {
      this.$axios
        .get("/acb/2.0/parkIncomeAnalysis/income/rank", {
          data: {
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            parkType: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            rankType: rankType,
            orderType: orderType,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkTopList = res.value;
          }
        });
    },

    searchData() {
      // 排行榜
      this.getRankingList(this.rankType, this.orderType);
      this.getRevenueRatio();
      this.getIncomeAnalysis();
    },
    // getTimeNow () {
    //   const startTime = new Date();
    //   const endTime = new Date();
    //   if (this.formInline.dateType == "day") {
    //     startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    //     endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    //   } else {
    //     startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
    //     endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
    //   }
    //   this.startTime = dateFormat(startTime, 'yyyy-MM-dd');
    //   this.endTime = dateFormat(endTime, 'yyyy-MM-dd');
    // },
    getDateType() {
      let dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      let val = "";
      switch (dateType) {
        case 1:
          val = "day";
          break;
        case 5:
          val = "week";
          break;
        case 2:
          val = "month";
          break;
        case 3:
          val = "year";
          break;
        default:
          break;
      }
      return val;
    },
    // 导出
    exportFileList() {
      const tempData = {
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        scopeId: this.formInline.scopeId,
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
      };
      const url = "/acb/2.0/parkRecord/statics/exportNight";
      exportExcelNew(url, tempData);
    },
  },
  created() {
    this.getArea();
  },
  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">

.chart-wrapper {
  margin-top: 16px;

  .chart-top-wrap {
    display: flex;
    flex-direction: row;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .padding16 {
    padding: 16px 16px 10px;
  }

  .padding5 {
    padding: 16px 16px 5px;
  }

  .chart1-wrap {
    width: 368px;
    margin-right: 16px;
    background: #fff;
  }

  .chart2-wrap {
    flex: 1;
    background: #fff;
  }

  .chart-bottom-wrap {
    margin-top: 16px;
    background: #fff;
  }
}
</style>
