<!--
 * @Author: your name
 * @Date: 2021-01-29 11:27:59
 * @LastEditTime: 2022-07-22 14:05:59
 * @LastEditors: faf
 * @Description: 停车收入分析
-->
<template>
  <div>
    <el-tabs class="tab-wrap" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane class="tab1-warp" label="停车收入分析" name="first">
        <income-analysis ref="incomeAnalysis" v-if="activeName == 'first'"></income-analysis>
      </el-tab-pane>
      <el-tab-pane class="tab2-warp" label="车场收入明细" name="second">
        <income-detail ref="incomeDetail" v-if="activeName == 'second'"></income-detail>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import incomeAnalysis from "./incomeAnalysis";
import incomeDetail from "./incomeDetail";
/* eslint-disable */
export default {
  data() {
    return {
      activeName: "first",
    };
  },
  components: {
    incomeAnalysis,
    incomeDetail,
  },
  methods: {
    handleClick(val) {
      // console.log('val.index', val.index)
      // val.index == '0' ? this.$refs.incomeAnalysis.searchData() : this.$refs.incomeDetail.searchData();
    },
  },
};
</script>
<style lang="stylus" scoped rel="stylesheet/stylus">
>>>.tab-wrap>.el-tabs__header {
  padding-left: 16px;
}
>>>.tab-wrap>.el-tabs__header .el-tabs__active-bar {
  position: absolute;
  background-color: #527BFF;
  height: 1;
  padding: 0 16px;
  left: -16px;
}
>>>.tab-wrap>.el-tabs__header .el-tabs__nav>.el-tabs__item {
  padding: 0 16px;
}
>>>.tab-wrap>.el-tabs__header .el-tabs__nav>.el-tabs__item:hover {
  color: #527bff;
}
>>>.tab-wrap>.el-tabs__header .el-tabs__nav>.is-active {
  padding: 0 16px;
  color: #527bff;
}
>>>.tab-wrap>.el-tabs__header>.el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  height: 1px;
}
.tab-wrap {
  margin-top: 16px;
  >>> .el-tabs__header {
    background: #fff;
    margin: 0;
  }
}
</style>
